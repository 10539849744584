.notFound {
    text-align: center;
    position: relative;
}

.notFound img {
    width: 100%;
}

.notFound h1 {
    position: absolute;
    top: 5vh;
    color: white;
    width: 85%;
    margin-left: 7.5%;
    font-variant: small-caps;
}